// components/ArticleList.tsx
import React from 'react';
import Link from 'next/link';
import { TFunction } from 'next-i18next';
import ImageCustom from '../elements/ImageCustom';
import TituloSeccionH2 from '../elements/TituloSeccionH2';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Texto from '../elements/Texto';
import { textosMini } from '@/constants/Dimens';

interface Props {
  t: TFunction;
  terminosBusqueda: string[];
}

const TerminosBusquedaWidget: React.FC<Props> = ({ t, terminosBusqueda }) => {

  return (
    (terminosBusqueda !== undefined && terminosBusqueda.length > 0) && (
      <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ textAlign: 'left', width: '100%' }}>
          <TituloSeccionH2 texto={t("common:articulos.libros_populares")} />
        </div>
        <div>
          <Accordion style={{ width: "93%", marginTop: 7 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              style={{}}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <Texto fontSize={textosMini}>{t("common:articulos.mostrar_titulos_libros")}</Texto>
              </div>
            </AccordionSummary>
            <AccordionDetails style={{ marginTop: -20 }}>
              <div className=''>
                <Texto fontSize={textosMini} >{t("common:articulos.compra_y_vende_libros")}</Texto>
                {terminosBusqueda.map((textoDescripcion, index) => (
                  <Texto fontSize={textosMini} key={index}>{textoDescripcion}</Texto>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </section>
    ));
};

export default TerminosBusquedaWidget;
