// components/ArticleList.tsx
import React from 'react';
import Link from 'next/link';
import { TFunction } from 'next-i18next';
import ImageCustom from '../elements/ImageCustom';
import TituloSeccionH2 from '../elements/TituloSeccionH2';

interface Props {
  t: TFunction;
}

export interface BannerExplicativoObj {
  titulo: string;
  imagen: string;
  alt: string;
  tittle: string;
  link: string;
}

const bannersExplicativos: BannerExplicativoObj[] = [
  {
    titulo: 'contenido:banner_amazon_ebooks.titulo_ebooks',
    imagen: '/images/anuncios/banner_ebooks.jpg',
    alt: 'contenido:banner_amazon_ebooks.alt_ebooks',
    tittle: 'contenido:banner_amazon_ebooks.title_ebooks',
    link: 'https://www.amazon.es/kindle-dbs/hz/signup?tag=quierolibrosa-21'
  },
  {
    titulo: 'contenido:banner_amazon_ebooks.titulo_audiolibros',
    imagen: '/images/anuncios/banner_audiolibros.jpg',
    alt: 'contenido:banner_amazon_ebooks.alt_audiolibros',
    tittle: 'contenido:banner_amazon_ebooks.title_audiolibros',
    link: 'https://www.amazon.es/hz/audible/mlp/mdp/discovery?actionCode=AMSTM1450129210001&tag=quierolibrosa-21'
  }
];

const BannerAmazoneBooks: React.FC<Props> = ({ t }) => {
  const [bannerExplicativoObj, setBannerExplicativoObj] = React.useState<BannerExplicativoObj>();

  React.useEffect(() => {
    generarBannerExplicativo();
  }, []);

  function generarBannerExplicativo() {
    //Genera aleatoriamente un banner explicativo
    const random = Math.floor(Math.random() * bannersExplicativos.length);
    setBannerExplicativoObj(bannersExplicativos[random]);
  }
  //alert(JSON.stringify(articles)) 
  return (
    (bannerExplicativoObj !== undefined && (
      <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ textAlign: 'left', width: '100%' }}>
          <TituloSeccionH2 texto={t(bannerExplicativoObj.titulo)} />
        </div>
        <Link title={t(bannerExplicativoObj.tittle)} href={bannerExplicativoObj.link} rel="noopener noreferrer" target="_blank">
          <ImageCustom className='bannerAmazon'  src={bannerExplicativoObj.imagen} alt={t(bannerExplicativoObj.alt)} width={383.94} height={74.52} />
        </Link>
      </section>
    ))
  );
};

export default BannerAmazoneBooks;
