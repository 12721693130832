// components/ArticleList.tsx
import React from 'react';
import { TFunction } from 'next-i18next';
import Subtitulo from '../elements/Subtitulo';
import TituloSeccionH2 from '../elements/TituloSeccionH2';


interface Props {
  t: TFunction;
}

const BannerExplicativo: React.FC<Props> = ({ t }) => {
  //alert(JSON.stringify(articles)) 
  return (
    <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', marginLeft: "10px", marginRight: "10px", marginBottom: "20px" }}>
      <div style={{ marginBottom: "20px" }}>
        <Subtitulo texto={t('contenido:banner_explicativo.texto_1')} />
        <Subtitulo texto={t('contenido:banner_explicativo.texto_2')} />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <TituloSeccionH2 texto={t('contenido:banner_explicativo.texto_3')} />
        <Subtitulo texto={t('contenido:banner_explicativo.texto_4')} />
      </div>
    </section>
  );
};

export default BannerExplicativo;
