import { black } from '@/constants/Colors';
import { subtitulo } from '@/constants/Dimens';
import { Typography } from '@mui/material';


//Definimos los tipos de datos que va a recibir el componente en forma de interfaz
interface TituloH1Props {
    texto: string;
    color?: string;
}

function Subtitulo({ texto, color }: TituloH1Props) {

    //Tamaño de texto a 25 px
    //Color de texto a primario

    const style: React.CSSProperties = {
        fontSize: subtitulo,
        color: color ? color : black,
        fontWeight: '300',
        userSelect: 'none',
    };

    
    return (
        <Typography sx={{ mt: 1 }} style={style}>
            {texto}
        </Typography>
    );

}

export default Subtitulo;
