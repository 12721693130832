import { mostrando, mostrandoPeque } from '@/constants/Dimens';
import Texto from '../elements/Texto';
import { useTranslation } from 'next-i18next';
import TituloSeccionH2 from '../elements/TituloSeccionH2';

export enum TipoUbicacion {
    ULTIMOS_LIBROS = 'ULTIMOS_LIBROS',
    LIBROS_CERCANOS = 'LIBROS_CERCANOS'
}

//Definimos los tipos de datos que va a recibir el componente en forma de interfaz
interface Props {
    tipoUbicacion: TipoUbicacion
    setTipoUbicacion: (tipoUbicacion: TipoUbicacion) => void
}

function LibrosCercanos({ tipoUbicacion, setTipoUbicacion }: Props) {

    const { t } = useTranslation();

    function setTipoUbicacionFunction(tipoUbicacion: TipoUbicacion) {
        setTipoUbicacion(tipoUbicacion);
    }
    
    return (
        <section style={{ display: 'flex', flexDirection: 'column' }}>
            <TituloSeccionH2 texto={t("contenido:mostrando.mostrando")} />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ cursor: "pointer" }} onClick={() => setTipoUbicacionFunction(TipoUbicacion.ULTIMOS_LIBROS)}>
                    <Texto fontWeight="300" fontSize={tipoUbicacion == TipoUbicacion.ULTIMOS_LIBROS ? mostrando : mostrandoPeque} >{t("contenido:mostrando.ultimos_libros")}</Texto>
                </div>
                <div style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => setTipoUbicacionFunction(TipoUbicacion.LIBROS_CERCANOS)}>
                    <Texto fontWeight="300" fontSize={tipoUbicacion == TipoUbicacion.LIBROS_CERCANOS ? mostrando : mostrandoPeque}>{t("contenido:mostrando.libros_cercanos")}</Texto>
                </div>
            </div>
        </section>
    );
}

export default LibrosCercanos;