// src/app/page.tsx
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Article, CategoriasString, CategoriasStringServer, Format, GetArticlesResponse, GetArticulosCercania, GetArticulosInicial, TextoBusqueda } from '@/objects/Articles';
import ArticulosQueries from '@/network/Articulos';
import { HeadObj } from '@/objects/Page';
import ListaArticulos from '@/components/elements/listas/lista_articulos/ListaArticulos';
import { addArticuloInternoToArticles, crearJSONLDCatalogo, numeroCajasPorDefecto, obtenerIdUltimoTotal, obtenerUltimaDistanciaDelUltimoArticulo, obtenerUltimoIdByCategoria } from '@/util/Articulos';
import { ServicesContext, useServices } from '@/context/ServicesProvider';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import TituloPrincipalH1 from '@/components/elements/TituloPrincipalH1';
import { pathToSection, traduccionesPorPathHead } from '@/util/internacionalizacion/TraduccionesPorPath';
import { getCookiePais } from '@/util/CookieStorage';
import ContenidoPrincipal from '@/components/elements/ContenidoPrincipal';
import BannerExplicativo from '@/components/widgets/BannerExplicativo';
import BannerDescargarAPP from '@/components/widgets/BannerDescargarAPP';
import BannerAmazoneBooks from '@/components/widgets/BannerAmazoneBooks';
import ListaCategorias from '@/components/elements/listas/lista_categorias/ListaCategorias';
import ListaDestacados from '@/components/elements/listas/lista_destacados/ListaDestacados';
import { axiosBaseURL } from '@/util/NetworkInstance';
import LibrosCercanos, { TipoUbicacion } from '@/components/widgets/LibrosCercanos';
import { getUbicacion } from '@/util/Ubicacion';
import { LocalizacionObj } from '@/objects/Localizacion';
import { IndiceLogro } from '@/objects/Gamificacion';
import { comprobarLogro } from '@/util/Gamificacion';
import { cargarDatosUsuario } from '@/util/LocalStorageLoaded';
import { addArticuloAmazonToArticles } from '@/util/Amazon';
import GoogleAds, { AdSenseFormat } from '@/components/widgets/Adsense/GoogleAds';
import { IDAdsense, SlotAnuncioDisplay, urlWeb } from '@/util/BaseURL';
import { idiomasDisponibles } from '@/util/Comun';
import TerminosBusquedaWidget from '@/components/widgets/TerminosBusqueda';

interface HomeProps {
  articles: Article[];
  pathActual: string;
  terminosBusqueda: string[];
}

const Home: React.FC<HomeProps> = ({ articles, pathActual, terminosBusqueda }) => {

  const { t } = useTranslation();

  //console.log("t: " + JSON.stringify(t));

  const { gamificationQueries, articulosQueries } = useServices();

  const [articlesState, setArticlesState] = useState<Article[]>(articles);

  //Para usar ubicacion
  const [tipoUbicacion, setTipoUbicacion] = useState<TipoUbicacion>(TipoUbicacion.ULTIMOS_LIBROS);
  //Con esto evitamos que se ejecute el useEffect en la primera renderización
  const isFirstRender = useRef(true);
  const isFirstRender2 = useRef(true);
  const isFirstRenderPosition = useRef(true);
  const [ultimaDistance, setUltimaDistance] = useState<number>(0);

  const [position, setPosition] = useState<LocalizacionObj | null>(null);

  //comprueba si el usuario ha iniciado sesion
  useEffect(() => {
    //throw new Error("Error Thrown on purpose to send it to Bugsink");
    const datosUsuario = cargarDatosUsuario();
    if (datosUsuario == null) {
      return;
    }
    //Comprueba que has iniciado sesion
    comprobarLogro(IndiceLogro.NUEVO_USER, gamificationQueries!, t, false);
    //Logros subir libros
    comprobarLogro(IndiceLogro.SUBIR_PRIMER_LIBRO, gamificationQueries!, t, false);
    comprobarLogro(IndiceLogro.SUBIR_5_LIBROS, gamificationQueries!, t, false);
    comprobarLogro(IndiceLogro.SUBIR_10_LIBROS, gamificationQueries!, t, false);
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setArticlesState([]);
  }, [tipoUbicacion]);

  useEffect(() => {
    if (isFirstRender2.current) {
      isFirstRender2.current = false;
      return;
    }
    if (articlesState.length === 0) {
      if (tipoUbicacion === TipoUbicacion.ULTIMOS_LIBROS) {
        obtenerUltimosLibros();
      } else {
        if (position) {
          obtenerLibrosCercanos();
        } else {
          obtenerPosicionGPS();
        }
      }
    }
  }, [articlesState, tipoUbicacion]);

  useEffect(() => {
    if (isFirstRenderPosition.current) {
      isFirstRenderPosition.current = false;
      return;
    }
    obtenerLibrosCercanos();
  }, [position]);

  const cargarMas = () => {
    if (tipoUbicacion === TipoUbicacion.ULTIMOS_LIBROS) {
      obtenerUltimosLibros();
    } else {
      if (position) {
        obtenerLibrosCercanos();
      } else {
        obtenerPosicionGPS();
      }
    }
  }

  function obtenerPosicionGPS() {
    getUbicacion().then((position) => {
      setPosition(position);
    }).catch((error) => {
      console.error('Error al obtener ubicación:', error);
    });
  }

  async function obtenerUltimosLibros() {
    const getArticulosInicial: GetArticulosInicial = {
      idUltimoCiencia: obtenerUltimoIdByCategoria(CategoriasStringServer.CIENCIA, articlesState),
      idUltimoComic: obtenerUltimoIdByCategoria(CategoriasStringServer.COMIC, articlesState),
      idUltimoEducacion: obtenerUltimoIdByCategoria(CategoriasStringServer.EDUCACION, articlesState),
      idUltimoHistoricos: obtenerUltimoIdByCategoria(CategoriasStringServer.HISTORIA, articlesState),
      idUltimoLiteratura: obtenerUltimoIdByCategoria(CategoriasStringServer.LITERATURA, articlesState),
      idUltimoInfantil: obtenerUltimoIdByCategoria(CategoriasStringServer.INFANTIL, articlesState),
      idUser: "",
      favoritos: "",
      type_app: 1,
      format: Format.JSON,
      num_cajas: numeroCajasPorDefecto(),
      conBoton: "",
      tipo_android_app: 1,
      type_reduced: "",
      country_code: getCookiePais(null).pais
    };

    articulosQueries?.getArticulosInicial(getArticulosInicial).then(async (response: AxiosResponse<GetArticlesResponse>) => {
      //añade articulos de amazon (ads)
      const articulosConAmazon = await addArticuloAmazonToArticles(response.data.articles);
      //alert(JSON.stringify(articulosConAmazon));
      const articulosPatrocionadores = await articulosQueries?.getArticulosPatrocinadores();
      const articulosFinal = await addArticuloInternoToArticles(articulosConAmazon, articulosPatrocionadores);

      setArticlesState([...articlesState, ...articulosFinal]);
    }).catch((error) => {
      console.error('Error al obtener artículos:', error);
    });
  }

  async function obtenerLibrosCercanos() {

    const getArticulosCercania: GetArticulosCercania = {
      ultimaDistance: ultimaDistance,
      latitudActual: Number(position?.latitude),
      longitudActual: Number(position?.longitude),
      ideUltimo: obtenerIdUltimoTotal(articlesState),
      type_app: 1,
      format: Format.JSON,
      num_cajas: numeroCajasPorDefecto(),
      conBoton: "false",
      tipo_android_app: 1,
      country_code: getCookiePais(null).pais
    };

    articulosQueries?.getArticulosCercania(getArticulosCercania).then(async (response: AxiosResponse<GetArticlesResponse>) => {
      //ultima distance es la distancia del ultimo libro
      setUltimaDistance(obtenerUltimaDistanciaDelUltimoArticulo(response.data.articles));
      const articulosConAmazon = await addArticuloAmazonToArticles(response.data.articles);
      const articulosPatrocionadores = await articulosQueries?.getArticulosPatrocinadores();
      const articulosFinal = await addArticuloInternoToArticles(articulosConAmazon, articulosPatrocionadores);

      setArticlesState([...articlesState, ...articulosFinal]);
    }).catch((error) => {
      console.error('Error al obtener artículos:', error);
    });
  }

  const styleMargin: React.CSSProperties = {
    margin: "20px"
  }

  return (
    <ContenidoPrincipal
      leftComponent={<div>Anuncio</div>}
      centerComponent={
        <>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TituloPrincipalH1 texto={t(`contenido:${pathActual}.titulo`)} />
          </div>
          <div style={styleMargin}>
            <BannerExplicativo t={t} />
            <BannerDescargarAPP t={t} />
          </div>
          <div>
            <GoogleAds client={IDAdsense} slot={SlotAnuncioDisplay} format={AdSenseFormat.HORIZONTAL} dataFullWidthResponsive={true} />
          </div>
          <div style={styleMargin}>
            <LibrosCercanos tipoUbicacion={tipoUbicacion} setTipoUbicacion={setTipoUbicacion} />
          </div>
          <div style={styleMargin}>
            <ListaCategorias t={t} categoria={CategoriasString.DEFECTO} />
          </div>
          <div style={styleMargin}>
            <BannerAmazoneBooks t={t} />
          </div>
          <div style={styleMargin}>
            <TerminosBusquedaWidget t={t} terminosBusqueda={terminosBusqueda} />
          </div>
          <div style={styleMargin}>
            <ListaDestacados t={t} />
          </div>
          <div>
            <GoogleAds client={IDAdsense} slot={SlotAnuncioDisplay} format={AdSenseFormat.HORIZONTAL} dataFullWidthResponsive={true} />
          </div>
          <div >
            <ListaArticulos articles={articlesState} onClick={() => { cargarMas() }} t={t} />
          </div>
        </>
      }
      rightComponent={<div>Anuncio 2</div>}
    />
  );
};

export default Home;


//Generacion de articulos en el servidor para SEO
export async function getServerSideProps(context: any) {
  //Traducciones
  let { locale } = context;

  if (!locale) {
    locale = 'es'; // Reemplaza 'es' con tu localización predeterminada
  }

  const pathActual = pathToSection(context.resolvedUrl);
  //console.log('Idioma Cookie:' + getCookiePais(context));
  const headerObj: HeadObj = await traduccionesPorPathHead(context, locale);

  let articulos: Article[] = [];

  const articulosQueries = new ArticulosQueries(axiosBaseURL());

  try {
    const getArticulosInicial: GetArticulosInicial = {
      idUltimoCiencia: "",
      idUltimoComic: "",
      idUltimoEducacion: "",
      idUltimoHistoricos: "",
      idUltimoLiteratura: "",
      idUltimoInfantil: "",
      idUser: "",
      favoritos: "",
      type_app: 1,
      format: Format.JSON,
      num_cajas: numeroCajasPorDefecto(),
      conBoton: "",
      tipo_android_app: 1,
      type_reduced: "",
      country_code: getCookiePais(context).pais
    };

    const response: AxiosResponse<GetArticlesResponse> = await articulosQueries.getArticulosInicial(getArticulosInicial);
    //console.log('Artículos:', response.data.articles);
    articulos = response.data.articles;

    headerObj.JSONLDPage = crearJSONLDCatalogo(articulos);

    //Crea el link canonical y los alternates
    if (locale === 'es') {
      headerObj.linkCanonical = urlWeb;
    } else {
      headerObj.linkCanonical = `${urlWeb}/${locale}`;
    }

    headerObj.alternates = idiomasDisponibles.map((loc) => {
      if (loc === 'es') {
        return { hreflang: loc, href: urlWeb }; // Para 'es', la URL base es suficiente
      } else {
        return { hreflang: loc, href: `${urlWeb}/${loc}` }; // Para otros locales, se agrega el locale a la URL
      }
    });

  } catch (error) {
    console.error('Error al obtener artículos:', error);
  }

  //console.log("PathActual " + pathActual);
  //Obtener terminos de busqueda para SEO:
  const response: AxiosResponse<TextoBusqueda[]> = await articulosQueries.getTerminosBusquedaPopulares();
  const terminosBusqueda = response.data.map((rowRespuestas) => rowRespuestas.texto_busqueda);

  //console.log("ServerSideTranslation " + JSON.stringify(await serverSideTranslations(locale, ['common', "contenido"])));
  return {
    props: {
      articles: articulos,
      terminosBusqueda: terminosBusqueda,
      headerObj: headerObj, // Pasar el objeto de encabezado
      pathActual: pathActual, //Se le pasa para seleccionar las traducciones propias de esta página (dentro de contenido)
      ...(await serverSideTranslations(locale, ['common', "contenido", "header"])),
    },
    //revalidate: 60, // Regenera la página cada 60 segundos
  };
}