// components/ArticleList.tsx
import React from 'react';
import Link from 'next/link';
import { TFunction } from 'next-i18next';
import Image from 'next/image';
import ImageCustom from '../elements/ImageCustom';
import { urlAPPAndroid, urlAPPIOS } from '@/util/BaseURL';

interface Props {
  t: TFunction;
}

const BannerExplicativo: React.FC<Props> = ({ t }) => {
  //alert(JSON.stringify(articles)) 
  return (
    <section style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
      <Link title={t("contenido:banner_descarga_app.title_ios")} href={urlAPPAndroid} rel="noopener noreferrer" target="_blank">
        <ImageCustom src="/images/icons/ic_google_play.png" alt={t("contenido:banner_descarga_app.alt_android")} width={162} height={54} />
      </Link>
      <Link title={t("contenido:banner_descarga_app.title_ios")} href={urlAPPIOS} rel="noopener noreferrer" target="_blank">
        <ImageCustom src="/images/icons/ic_app_ios.png" alt={t("contenido:banner_descarga_app.alt_ios")} width={165} height={57} />
      </Link>
    </section>
  );
};

export default BannerExplicativo;
