// components/ArticleList.tsx
import React, { useEffect, useState } from 'react';
import { CategoriasString, SubCategoriasString } from '@/objects/Articles';
import { TFunction } from 'i18next';
import TituloSeccionH2 from '../../TituloSeccionH2';
import { CategoriaObj, categorias_bachillerato, categorias_defecto, categorias_educacion, categorias_literatura, categorias_primaria, categorias_secundaria } from '@/objects/Categorias';
import RowCategoria from './RowCategoria';


interface Props {
  t: TFunction;
  categoria?: CategoriasString;
  subcategoria?: SubCategoriasString;
}

//Objetos/categorias
const listaCategorias = {
  defecto: categorias_defecto,
  literatura: quitarPrimerElemento(categorias_literatura),
  educacion: quitarPrimerElemento(categorias_educacion),
  educacion_primaria: quitarPrimerElemento(categorias_primaria),
  educacion_secundaria: quitarPrimerElemento(categorias_secundaria),
  educacion_bachillerato: quitarPrimerElemento(categorias_bachillerato),
}

function quitarPrimerElemento(array: CategoriaObj[]): CategoriaObj[] {
  return array.slice(1);
}

const categoriasSelect = (categoria: string) => {
  const key = categoria.toString() as keyof typeof listaCategorias;
  //alert("key: " + JSON.stringify(listaCategorias.hasOwnProperty(key) ? listaCategorias[key] : undefined));
  return listaCategorias.hasOwnProperty(key) ? listaCategorias[key] : undefined;
}

const ListaCategorias: React.FC<Props> = ({ t, categoria, subcategoria }) => {

  const [lista_categorias, setListaCategorias] = useState<CategoriaObj[] | undefined>(undefined);

  useEffect(() => {
    //alert("Categoria " + categoria + " subcategoria: " + subcategoria);
    if (categoria) {
      const categoriasObtenidas = categoriasSelect(categoria + (subcategoria !== "" && subcategoria !== undefined ? ("_" + subcategoria) : ""))
      //alert(JSON.stringify(categoriasObtenidas));
      setListaCategorias([]);
      //Espera 15 segundos para que se carguen las categorías
      setTimeout(() => {
        setListaCategorias(categoriasObtenidas);
      }, 100);
    }
  }, [categoria, subcategoria]);



  return (
    lista_categorias && (
      <section>
        <TituloSeccionH2 texto={t('common:categorias.titulo')} />
        <div style={{ display: 'flex', overflowX: 'auto' }}>
          {lista_categorias.map((categoria, index) => (
            //console.log("categoria: " + JSON.stringify(categoria)),
            <RowCategoria categoria={categoria} t={t} key={"cat_" + categoria.categoriaString} />
          ))}
        </div>
      </section>
    )
  );
};

export default ListaCategorias;

