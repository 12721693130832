// components/ArticleList.tsx
import React, { useContext, useEffect, useState } from 'react';
import { Article, CategoriasInternasAnuncios, GetArticlesResponse } from '@/objects/Articles';
import RowArticulo from '../lista_articulos/RowArticulo';
import { ServicesContext, useServices } from '@/context/ServicesProvider';
import { TFunction } from 'i18next';
import TituloSeccionH2 from '../../TituloSeccionH2';
import { AxiosResponse } from 'axios';
import { urlWeb } from '@/util/BaseURL';
import { getCookiePais } from '@/util/CookieStorage';
import { filtarMoneda } from '@/util/Monedas';


interface Props {
  t: TFunction;
}

const ListaDestacados: React.FC<Props> = ({ t }) => {

  const [articles, setArticles] = useState<Article[]>([]);

  const { articulosQueries } = useServices();


  useEffect(() => {
    articulosQueries?.getDestacadosPrincipal().then((response: AxiosResponse<GetArticlesResponse>) => {
      // alert(JSON.stringify(response.data.articles));
      const articleExtraDestacado: Article = {
        id_article: -2,
        id_user: -1,
        title: t("common:articulos.destaca_gratis_tus_libros"),
        content: "",
        description: "",
        image: `${urlWeb}/images/anuncios/fondo_destacar.jpg`,
        articulo_categoria: CategoriasInternasAnuncios.PREMIUM,
        linkExtra: "/dashboard/premium",
        editorial: "",
        author: "",
        contact_email: "",
        contact_phone: "",
        city: "",
        town: "",
        pais: "",
        prize: 0,
        moneda: filtarMoneda(getCookiePais(null).moneda),
        tases_shipping: 0,
        coordinates: "",
        post_type: "",
        sub_type: "",
        estado: "",
        tapa: "",
        whatsapp: 0,
        isbn: "",
        emailgoogle: "",
        fotogoogle: "",
        namegoogle: "",
        date: "",
        lat: 0,
        lng: 0,
        destacado: "",
        verificado: 0,
        vendido: 0,
        email_comprador: "",
        id_transaccion: "0",
        visto: 0,
        veces_telefono: 0,
        veces_email: 0,
        mostrar: 1,
        name: "",
        email: "",
        esTienda: 0,
        avatar: "",
      };

      const respuestaArticulos: Article[] = response.data.articles;
      respuestaArticulos.unshift(articleExtraDestacado);
      setArticles(respuestaArticulos);
    }).catch((error) => {
      console.error('Error al obtener artículos:', error);
    });
  }, []);

  return (
    <section>
      <TituloSeccionH2 texto={t('common:articulos.libros_destacados')} />
      <div style={{ display: 'flex', overflowX: 'auto' }}>
        {articles.map((article, index) => (
          <div style={{ margin: "10px" }} key={"destacado_" + article.articulo_categoria + article.id_article}>
            <RowArticulo t={t} article={article} key={"card_" + article.articulo_categoria + article.id_article} width={148} heigth={180} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default ListaDestacados;

