// components/ArticleList.tsx
import React from 'react';
import Link from 'next/link';
import { Card } from '@mui/material';
import Image from 'next/image';
import { CategoriaObj } from '@/objects/Categorias';
import { TFunction } from 'next-i18next';
import Texto from '../../Texto';
import { primaryDark, white } from '@/constants/Colors';
import ImageCustom, { TypeLoading } from '../../ImageCustom';

interface Props {
  categoria: CategoriaObj;
  t: TFunction
}

const RowCategoria: React.FC<Props> = ({ categoria, t }) => {

  //alert(JSON.stringify(articles)) 
  return (
    <div style={{ padding: "10px" }}>
      <Card key={"card_" + categoria.categoriaString} style={{
        width: "100px", height: "100px", padding: "10px",
        backgroundImage: `url(/images/fondo_parallax.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: white,
      }}>
        <Link title={t(categoria.title)} href={categoria.link} style={{ textDecoration: 'none' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <div>
              <ImageCustom
                src={categoria.icono}
                alt={t(categoria.alt)}
                width={50}
                height={50}
                loading={TypeLoading.LAZY}
              />
            </div>
            <div>
              <Texto color={primaryDark}>{t(categoria.titulo)}</Texto>
            </div>
          </div>
        </Link>
      </Card>

    </div>
  );
};

export default RowCategoria;
